import { apiUrl } from '@/config/global'

export default class BrandService{
  constructor(http){
    this._http = http
  }

  get (id) {
    return fetch(`${apiUrl}/api/brand/${id}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  update (id, name, agreement) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('name', name)
    formData.append('agreement', agreement)

    return fetch(`${apiUrl}/api/brand`, {
      method: 'put',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
      body: formData
    }).then(response => response.json())
  }

  getAll () {
    return fetch(`${apiUrl}/api/brand`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  getBrandPriceByBrandId (brandId, numberModel) {
    return fetch(`${apiUrl}/api/brand/prices/${brandId}/${numberModel}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      }
    }).then(response => response.json())
  }

  getBrandPrice (id) {
    return fetch(`${apiUrl}/api/brand/price/${id}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
    }).then(response => response.json())
  }

  getBrandNumberModelByBrandId (brandId) {
    return fetch(`${apiUrl}/api/brand/model/${brandId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      }
    }).then(response => response.json())
  }

  getPrices (query) {
    return fetch(`${apiUrl}/api/brand/prices/calculated?${query}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      }
    }).then(response => response.json())
  }

  updatePrice (id, description, price) {
    const json = {
      id,
      description,
      price
    }
    return fetch(`${apiUrl}/api/brand/price`, {
      method: 'put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`
      },
      body: JSON.stringify(json)
    }).then(response => response.json())
  }

  getAgreement (id) {
    return fetch(`${apiUrl}/api/brand/agreement/${id}`, {
      method: "get"
    }).then(response => response.blob())
  }
}