<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <div class="min-heigth-61vh">
        <b-row>
          <b-col cols="12">
            <ProfileLine title="Editar Modelo" :items="profileItems" />
          </b-col>
        </b-row>
        <b-form @submit.stop.prevent="submit">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Id">
                <b-form-input
                  disabled
                  v-model="form.id"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Número de Lojas">
                <b-form-input
                  disabled
                  v-model="form.numberModel"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Preço">
                <b-form-input
                  v-model="form.price"
                  type="text"
                  v-money="money"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Descrição">
                <b-form-input
                  v-model="form.description"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" >
              <div class="mt-3 mx-auto container-button">
                <b-button variant="secondary" class="mr-2" @click="back">
                  Voltar
                </b-button>
                <b-button variant="info" @click="submit">
                  Enviar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
       </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import BrandService from '@/service/BrandService'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ProfileLine from '@/components/ProfileLine'
import Spinner from '@/components/Spinner'

export default {
  components: { Header, Footer, ProfileLine, Spinner  },

  data () {
    return {
      profileItems: [
        {
          text: 'Admin',
          href: '/admin'
        },
        {
          text: 'Marcas',
          href: '/admin/brand'
        },
        {
          text: 'Item',
          active: true
        }
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      brandService: null,
      spinner: false,
      form: {},
    }
  },
  methods: {
    get (id) {
      this.spinner = true
      this.brandService.getBrandPrice(id)
        .then(response => {
          this.form = response
          this.spinner = false
        })
    },
    back () {
      this.$router.push(`/admin/brand`) 
    },
    submit () {
      this.spinner = true
      this.brandService.updatePrice(this.form.id, this.form.description, this.form.price)
        .then(response => {
          if (response) {
            Swal.fire({
              title: 'Sucesso',
              text: 'Marca salva com sucesso',
              icon: 'success'
            }).then(() => {
              this.spinner = true
              this.$router.push(`/admin/brand`) 
            })
          } else {
            Swal.fire({
              title: 'Erro',
              text: 'Erro Inesperado, Tente novamente',
              icon: 'error'
            })
          }
        })
        .finally(() => {
          this.spinner = false
        })
    }
  },
  mounted () {
    this.brandService = new BrandService()
    this.get(this.$route.params.id)
  }
}
</script>

<style>
  .min-heigth-61vh {
    min-height: 61vh;
  }

  body {
    background: #F5F5F5;
  }

  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>