<template>
  <div class="mt-4">
    <b-breadcrumb :items="items"></b-breadcrumb>
    <div class="container-profile">
      <div class="flex-item-1">
        <h3> {{ title }} </h3>
      </div>
      <div class="flex-item-3">
        <b-button variant="link" @click="logout()">
          Logout
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: function(){
    return {
      name: 'Matheus Silva'
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('sgbAuth')
      this.$router.push(`/login`) 
    }
  }
}
</script>

<style>
.container-profile {
  display: flex;
}
.flex-item-1 {
  flex-grow: 1;
  width: 80%;
}

.flex-item-3 {
  text-align: right;
  flex-grow: 1;
}
</style>